<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="'color3 color3Text--text'"
        v-if="teams.length > 0"
        v-on="on"
        rounded
        :loading="loading"
        :x-small="xs"
        :text="text"
      >
        Assign a lineup
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Match Lineup</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1 color1Text--text" @click.stop="dialog=false" small fab>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <div v-if="false">{{ lineupLock }}</div>
      <div v-if="false">canEdit : {{ canEdit }}</div>
      <div v-if="false">canView : {{ canView }}</div>
      <v-card-text class="pt-2">
        <v-alert type="error" v-if="lineupLock && lineupLock.isLocked" prominent text>
          Lineups have been locked
          <div v-if="canEdit" class="caption">(Editing is only enable because you are an event admin)</div>
        </v-alert>
        <v-alert type="error" v-if="team && lineupLock && !lineupLock.isLocked && !canEdit" prominent text>
           These lineup are hidden until locked
          <div class="caption">(Because you are the opposing coach)</div>
        </v-alert>
        <v-select
          :items="teams"
          v-model="teamId"
          label="Team"
          item-text="name"
          item-value="id"
          color="color3"
          item-color="color3"
          outlined
          hide-details
          class="mb-1"
          :disabled="teams.length < 2"
        ></v-select>
        <v-expand-transition>
          <lineup-selector
            v-if="team && canView"
            :key="team.id"
            :team="team"
            :currentLineup="currentLineup"
            :division="division"
            @loading-change="onLoadingChange"
            @valid-change="valid = $event"
            :active="dialog"
            ref="wiz"
            :name="name"
            :disabled="!canEdit"
            :match="match"
          ></lineup-selector>
        </v-expand-transition>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="success white--text"
          small fab
          :disabled="!valid"
          :loading="loading"
          @click.stop="save"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
const LineupSelector = () => import('@/components/Team/Lineups/LineupWizard.vue')

export default {
  props: ['teamIds', 'matches', 'division', 'name', 'xs', 'text', 'lineupLock'],
  data () {
    return {
      dialog: false,
      loading: false,
      teamId: null,
      lineupId: null,
      valid: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getTeam', 'userTeams', 'view', 'getTeamToken']),
    teams () {
      const g = this.getTeam
      const t = this.teamIds.filter(f => this.view.adminAndRefs || this.userTeams.includes(f))
      return t.map(m => g(m))
    },
    canEdit () {
      const id = this.teamId
      if (!id) return false
      const ll = this.lineupLock
      if (!ll || !ll.hasLock) return true
      const isCoach = this.teamIds.filter(f => this.userTeams.includes(f)).length > 0
      const admin = this.view.adminAndRefs
      const isMyTeam = this.myTeamIds.includes(id)

      if (isCoach) {
        if (admin) {
          return ll.isLocked ? true : isMyTeam
        } else {
          return ll.isLocked ? false : isMyTeam
        }
      }
      // not a coach
      if (admin) {
        return true
      }

      return false
    },
    canView () {
      const id = this.teamId
      if (!id) return false
      const ll = this.lineupLock
      if (!ll || !ll.hasLock) return true
      const isCoach = this.teamIds.filter(f => this.userTeams.includes(f)).length > 0
      const admin = this.view.adminAndRefs
      const isMyTeam = this.myTeamIds.includes(id)

      if (isCoach) {
        return ll.isLocked || isMyTeam
      }
      // not a coach
      if (admin) {
        return true
      }

      return false
    },
    myTeamIds () {
      return this.teamIds.filter(f => this.userTeams.includes(f))
    },
    adminTeamIds () {
      return this.teamIds.filter(f => this.view.adminAndRefs)
    },
    match () {
      return this.matches && this.matches.length > 0 && this.matches[0]
    },
    currentLineup () {
      return this.teamId && this.match && this.match.lineups && this.match.lineups.find(f => f.teamId === this.teamId)
    },
    currentLineupId () {
      return this.currentLineup ? this.currentLineup.id : 0
    },
    team () {
      return this.teamId && this.getTeam(this.teamId)
    },
    futureMatches () {
      return this.tournament.matches.filter(f => f.activeTeamIds.includes(this.teamId) && f.unix && f.unix > this.match.unix)
    },
    dto () {
      const dto = this.$refs.wiz.dto
      const m = this.matches.map(m => m.dto)
      // if (dto.applyAll) {
      //   m.push(...this.futureMatches.map(m => m.dto))
      // }

      return {
        matches: m,
        teamId: this.teamId,
        lineup: dto,
        futureMatches: dto.applyAll ? this.futureMatches.map(m => m.dto) : []
      }
    },
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    }
  },
  methods: {
    getDto () {
      return {
        matches: this.matches.map(m => m.dto),
        teamId: this.teamId,
        lineup: this.$refs
      }
    },
    reset () {
      this.teamId = null
      this.lineupId = null
      if (this.teams.length === 1) this.teamId = this.teams[0].id
    },
    save () {
      console.log(this.dto)
      this.loading = true
      this.$VBL.team.lineups.assign(this.dto, this.teamToken)
        .then(e => { this.dialog = false })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    onLoadingChange (v) {
      this.loading = v
    }
  },
  watch: {
    dialog: 'reset',
    currentLineup: function (val) {
      this.lineupId = val ? val.id : null
    }
  },
  components: {
    LineupSelector
  }
}
</script>
